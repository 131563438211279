import { Tabs, usePanelState, useTabState } from '@bumaga/tabs'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image' // to take image data and render it
import React, { Fragment, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import CloseIconSVG from '../icons/closeIconSVG'

const Tab = ({ children, className }) => {
  const { onClick, isActive } = useTabState()

  return (
    <button
      onClick={onClick}
      className={`${className} ${isActive && 'is-active'}`}
    >
      {children}
    </button>
  )
}

const Panel = ({ children, className }) => {
  const isActive = usePanelState()
  return isActive ? <div className={className}>{children}</div> : null
}

const Why = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { base: { eq: "why.json" } }) {
        nodes {
          childJson {
            people {
              images {
                portrait {
                  childrenImageSharp {
                    gatsbyImageData
                  }
                }
                flag {
                  childrenImageSharp {
                    gatsbyImageData
                  }
                }
              }
              jobTitle
              name
              quote
              country
            }
          }
        }
      }
    }
  `)
  const peopleData = nodes[0].childJson.people

  const [modalOpen, setOpenModal] = useState(null)

  const showModal = i => {
    setOpenModal(i)
  }

  const hideModal = () => {
    setOpenModal(null)
  }

  return (
    <section id="why">
      <div className="pt-8 pb-12 layout-space md:pt-12 md:pb-20 xl:pt-20 xl:pb-32">
        <div className="mb-16 md:text-center">
          <h2 className="mb-4 heading">많은 사람들이 VoxRec로 음성을 텍스트로 변환하는 이유?</h2>
          <p className="mx-auto md:max-w-2xl sub-heading">
          4만 명 이상의 전문가들이 업무에 있어 시간절약을 위해 이 어플을 사용하고 있습니다. 사용자 일부에게 음성텍스트 변환기능을 어떻게 사용하고 있는지를 물었습니다. 사용자들의 의견은 이렇습니다.
          </p>
        </div>
        {/* ---------------------------------------------------------------------------------------------------- mobile section  */}
        <div className="flex flex-wrap justify-between max-w-sm mx-auto md:hidden">
          {peopleData.map((each, i) => (
            <Fragment key={`mobile-button and modal -${each.name} ${i}`}>
              <button
                className="relative px-2 mb-16 bg-white shadow-xl appearance-none select-none rounded-xl h-147px focus:outline-none"
                style={{ width: 'calc(50% - 0.5rem' }}
                onClick={() => showModal(i)}
              >
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                  <div className="relative">
                    <GatsbyImage
                      image={
                        each.images.portrait.childrenImageSharp[0]
                          .gatsbyImageData
                      }
                      alt="Profile Photo"
                      className="rounded-full w-14 h-14 ring-2 ring-gray-200"
                      // Safari needs bourder-radius on <img/> too
                      imgClassName="rounded-full"
                    />
                    <GatsbyImage
                      image={
                        each.images.flag.childrenImageSharp[0].gatsbyImageData
                      }
                      alt="Profile Photo"
                      className="absolute right-0 w-4 h-4 rounded-full bottom-1 ring-1 ring-gray-200"
                      imgClassName="rounded-full"
                    />
                  </div>
                </div>
                <div className="h-full pt-10 text-center">
                  <p className="text-black small">{each.name}</p>
                  <p className="text-sm text-black bold">{each.jobTitle}</p>
                  <p className="absolute transform -translate-x-1/2 bottom-3 left-1/2 text-accent xs-small bold">
                    보기
                  </p>
                </div>
              </button>
              <Modal show={modalOpen === i} onHide={hideModal}>
                <button
                  className="ml-auto focus:outline-none"
                  onClick={hideModal}
                  aria-label="Close"
                >
                  <CloseIconSVG />
                </button>
                <div className="flex items-center mb-4">
                  <div className="relative mr-6">
                    <GatsbyImage
                      image={
                        each.images.portrait.childrenImageSharp[0]
                          .gatsbyImageData
                      }
                      alt="Profile Photo"
                      className="rounded-full w-14 h-14 ring-2 ring-gray-200"
                      imgClassName="rounded-full"
                    />
                    <GatsbyImage
                      image={
                        each.images.flag.childrenImageSharp[0].gatsbyImageData
                      }
                      alt="Profile Photo"
                      className="absolute right-0 w-6 h-6 rounded-full bottom-1 ring-1 ring-gray-200"
                      imgClassName="rounded-full"
                    />
                  </div>
                  <div className="mb-2">
                    <p className="mb-1 text-black small">{each.name}</p>
                    <p className="mb-0 text-sm text-black bold">
                      {each.jobTitle}
                    </p>
                  </div>
                </div>
                <hr className="modal-hr" />
                <p className="pb-4 text-sm leading-7">{each.quote}</p>
              </Modal>
            </Fragment>
          ))}
        </div>
        {/* ---------------------------------------------------------------------------------------------------- desktop section  */}
        <div className="hidden max-w-6xl py-8 mx-auto bg-white shadow-xl md:flex rounded-xl shift-right">
          <Tabs>
            <div className="w-2/5">
              {peopleData.map((each, i) => (
                <Tab
                  className={`block w-full focus:outline-none tab ${
                    i === 0
                      ? 'first'
                      : i === peopleData.length - 1
                      ? 'last'
                      : ''
                  }`}
                  key={`tab for - ${each.name}`}
                >
                  <div className="w-full px-2 py-2 lg:px-8">
                    <div className="flex items-center w-full">
                      <div>
                        <GatsbyImage
                          image={
                            each.images.portrait.childrenImageSharp[0]
                              .gatsbyImageData
                          }
                          alt="Profile Photo"
                          className="mr-4 rounded-full w-72px h-72px ring-2 ring-gray-200"
                          imgClassName="rounded-full"
                        />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between">
                          <p className="text-sm text-black">{each.name}</p>
                          <div className="flex">
                            <p className="hidden pr-3 text-sm text-black lg:block">
                              {each.country}
                            </p>
                            <GatsbyImage
                              image={
                                each.images.flag.childrenImageSharp[0]
                                  .gatsbyImageData
                              }
                              alt="Profile Photo Flag"
                              className="w-6 h-6 rounded-full ring-1 ring-gray-200"
                              imgClassName="rounded-full"
                            />
                          </div>
                        </div>
                        <div className="flex">
                          <p className="mb-0 text-sm text-black lg:text-lg bold whitespace-nowrap">
                            {each.jobTitle}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              ))}
            </div>
            <div className="relative flex flex-col items-center justify-center w-3/5 p-8 gradient-border-left">
              {peopleData.map(each => (
                <Panel key={`quote from - ${each.name}`}>
                  <p className="quote">{each.quote}</p>
                </Panel>
              ))}
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  )
}

export default Why
