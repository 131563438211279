import { graphql } from 'gatsby'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import About from '../components/about'
import Contact from '../components/contact'
import Cta from '../components/cta'
import Features from '../components/features'
import LiveChatLayout from '../components/LiveChatLayout'
import Pricing from '../components/pricing'
import Reviews from '../components/reviews'
import Seo from '../components/seo'
import TopFold from '../components/topFold'
import Why from '../components/why'

const IndexPage = ({ data }) => {
  return (
    <LiveChatLayout>
      <Seo title="음성을 텍스트로 변환 | 아이폰, 아이패트, 애플워치에서 음성메모를 텍스트로 변환해보십시오." />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <TopFold
        appStoreLink={data.site.siteMetadata.appStoreLink}
        videoLink={data.site.siteMetadata.videoLink}
      />
      <About />
      <Why />
      <Cta appStoreLink={data.site.siteMetadata.appStoreLink} />
      <Features />
      <Pricing appStoreLink={data.site.siteMetadata.appStoreLink} />
      <Reviews />
      <Contact appStoreLink={data.site.siteMetadata.appStoreLink} />
    </LiveChatLayout>
  )
}
export default IndexPage

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
        videoLink
      }
    }
  }
`
