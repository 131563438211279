import React, { useRef } from 'react'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'
import nextToApp from './illustrations/next-to-app.svg'

const TopFold = ({ appStoreLink, videoLink }) => {
  const ref = useRef()
  return (
    <section id="home">
      <div className="pb-10 layout-space md:flex md:items-center md:pb-16 lg:pb-20 xl:pb-28 md:pt-12">
        <div className="md:w-1/2">
          <div className="pt-8 mb-10 text-center xl:pt-0 md:text-left lg:ml-20">
            <h1 className="mb-4 heading">
              회의를 녹음하고 바로 텍스트로 받아쓸 수 있습니다!
            </h1>
            <p className="mb-10 sub-heading">
            VoxRec은 음성텍스트 변환기능이 내장된 음성 녹음기입니다. 음성메모를 문자로 변환해주는 최신 AI 기술이 적용되었습니다. 이러한 변환자료를 메일함 또는 클라우드 폴더에 자동으로 전송합니다.
            </p>
            <div className="flex flex-col items-center md:flex-row">
              <a href={appStoreLink} target="_blank" rel="noreferrer">
                <AppStoreDarkLargeSVG className="mb-4 md:mr-6 md:mb-0 app-store-link" />
              </a>
              <p className="small">
                아이폰, 아이패드 및 애플워치에서 <br/>사용가능합니다.
              </p>
            </div>
          </div>
        </div>
        <div className="relative md:w-1/2 md:ml-10">
          <div className="img-container">
            <img
              width="654"
              height="509"
              className="top-fold-img"
              src={nextToApp}
              alt="Voice recording to text"
              decoding="async"
            />
            <video
              src={videoLink}
              className="react-player absolute"
              style={{
                top: '5%',
                left: '14.5%',
                height: '84%',
                width: 'auto',
                borderRadius: 20,
              }}
              preload="auto"
              loop
              muted
              playsInline
              autoPlay
              ref={ref}
              onCanPlay={() => {
                // w/a for React issue https://github.com/facebook/react/issues/10389
                const el = ref.current
                el.muted = true
                el.defaultMuted = true
                el.setAttribute('webkit-playsinline', 'true')
                el.setAttribute('x5-playsinline', 'true')
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TopFold
