import React from 'react'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'

const Pricing = ({ appStoreLink }) => {
  return (
    <section id="pricing">
      <div className="pt-4 md:pt-12 layout-space">
        <div className="max-w-6xl pb-12 mx-auto lg:px-10 md:pb-20 xl:pb-24">
          <div className="mb-12 md:text-center md:mb-16">
            <h2 className="mb-4 heading">요금</h2>
            <p className="md:max-w-xl sub-heading md:mx-auto">
              VoxRec은 무료 다운로드가 가능합니다. 이 어플의 녹음기능은 완전 무료입니다. 음성문자 변환서비스에 대해서는 매월 구독 또는 사용량에 따른 요금제를 제공하고 있습니다.
            </p>
          </div>

          <div className="md:flex md:flex-row-reverse md:items-center">
            <div className="text-center md:flex md:flex-wrap md:text-left xl:w-3/5 md:w-1/2 md:items-start">
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">핵심내용 다시 듣기</h5>
                <p className="small">
                  재생기간 동안 단어에 대한 시각적 강조를 통해 발언자와 토의내용을 확인할 수 있습니다. 재생하고자 하는 단어를 선택하시면 됩니다..
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">양식 및 편집</h5>
                <p className="small">
                  여러분의 아이폰이나 아이패드에서 직접 녹음내용에 설명을 추가하거나,문서변환된 내용이나 양식을 수정하거나 문구에 강조표시를 할 수 있습니다.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">중요시간별 표시</h5>
                <p className="small">
                  논의되는 내용이 중요한 부분인 경우, 녹음에서 중요부분에 대한 표시를 할 수 있고, 한 번의 선택으로 다시 확인할 수 있습니다.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">백업 및 동기화</h5>
                <p className="small">
                  여러분의 녹음내용과 문서변환내용을 Dropbox, Google Drive, iCloud에 안전하게 보관할 수 있습니다.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 xl:w-2/5 card-background-image">
              <div className="z-10 max-w-sm px-6 py-8 mx-auto bg-white shadow-lg md:mx-0 md:px-10 md:py-12 rounded-xl bg-10">
                <div className="mb-4">
                  <p className="from">분당</p>
                </div>
                <div className="flex items-end mb-4">
                  <h3 className="big-money">81원</h3>
                  <p className="mb-1 text-black md:text-3xl">
                    부터
                  </p>
                </div>
                <hr className="mb-2" />
                <h4 className="mb-6 text-accent">
                  30분 무료{' '}
                  <span className="lg:hidden md:hidden text-accent">
                    음성을 텍스트 변환하세요
                  </span>
                </h4>
                <div className="md:justify-between md:flex md:items-center">
                  <a href={appStoreLink} target="_blank" rel="noreferrer">
                    <AppStoreDarkLargeSVG className="mb-4 app-store-link md:mb-0 md:mr-8" />
                  </a>
                  <p className="small">
                    아이폰, 아이패드 및 애플워치에서 사용가능합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Pricing
