import React from 'react'
import topFoldSVGReversed from './illustrations/topFoldSVGReversed.svg'

const About = () => {
  return (
    <section id="about">
      <div className="pb-10 md:flex md:flex-row-reverse md:pb-16 lg:pb-20 about-layout">
        <div className="px-4 md:w-1/2 md:justify-center md:flex md:flex-col">
          <div className="mb-6 md:mb-0 md:max-w-xl lg:pl-20">
            <h2 className="mb-4 heading">
              분당 81원으로 뛰어난 정확도로 음성을 텍스트로 변환
            </h2>
            <p className="sub-heading">
              중요한 내용이나 번쩍이는 아이디어를 자주 잊지 않으십니까? 빠르게 진행되는 회의, 의사의 진료, 막 떠오르는 창의적 아이디어와 같은 다양한 상황을 음성메모로 기록하시고, 이를 텍스트로 다시 살펴보십시오. 이를 통해 시간과 비용을 절약할 수 있습니다.
            </p>
          </div>
        </div>
        <div className="hidden md:w-1/2 md:block md:pr-10 img-container">
          <img
            width="663"
            height="404"
            src={topFoldSVGReversed}
            alt="About"
            className="about-img"
            decoding="async"
          />
        </div>
      </div>
    </section>
  )
}

export default About
