import React from 'react'
import BookMarkSVG from '../icons/bookMarkSVG'
import ClockSVG from '../icons/clockSVG'
import SecuritySVG from '../icons/securitySVG'

const Features = () => {
  return (
    <section id="features">
      <div className="pt-16 pb-8 md:pt-12 layout-space lg:pt-20 xl:pt-32 xl:pb-32">
        <div className=" md:text-center md:mb-16">
          <h2 className="mb-3 heading">단순한 텍스트 변환어플 이상의 어플입니다</h2>
          <p className="mb-8 sub-heading">
            생산성 기능은 기록과 문서 변환으로 동일한 시간에 이전보다 더 많은 부분을 달성할 수 있도록 해줄 것입니다.
          </p>
        </div>
        <div className="max-w-6xl mx-auto md:flex lg:px-10">
          <div className="max-w-sm px-4 mx-auto mb-6 text-center bg-white shadow-xl xl:px-8 rounded-xl md:mx-2 md:flex-1 blue-dot-after">
            <div className="pt-6 pb-4">
              <ClockSVG className="w-12 h-12 mx-auto" />
            </div>
            <h3 className="uppercase bold sub-heading">이메일로 받아쓰기</h3>
            <h4 className="my-1 sub-heading text-accent bold">
              몇 분 이내에
            </h4>
            <p className="pb-6 small">
              여러분의 음성메모는 문자로 변환되어 자동으로 즉시 이메일로 전송됩니다.
            </p>
          </div>
          <div className="max-w-sm px-4 mx-auto mb-6 text-center bg-white shadow-xl xl:px-8 rounded-xl md:mx-2 md:flex-1">
            <div className="pt-6 pb-4">
              <SecuritySVG className="w-12 h-12 mx-auto" />
            </div>
            <h3 className="uppercase bold sub-heading">항상 보안을 유지하고</h3>
            <h4 className="my-1 sub-heading text-accent bold">항상 동기화되어 있습니다</h4>
            <p className="pb-6 small">
              회의록, 기록, 음성메모, 문자변환내용 모두는여러분의 클라우드 (iCloud, Dropbox, Google Drive)에 백업으로 유지됩니다.
            </p>
          </div>
          <div className="max-w-sm px-4 mx-auto mb-6 text-center bg-white shadow-xl xl:px-8 rounded-xl md:mx-2 md:flex-1">
            <div className="pt-6 pb-4">
              <BookMarkSVG className="w-12 h-12 mx-auto" />
            </div>
            <h3 className="uppercase bold sub-heading">산업의 선두주자</h3>
            <h4 className="my-1 sub-heading text-accent bold">
              AI 문자 변환
            </h4>
            <p className="pb-6 small">
              사람이 직접 수행하는 문자 변환은 많은 시간이 소요됩니다. VoxRec을 통해 대화나 회의내용을 더욱 효과적으로 변환할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Features
